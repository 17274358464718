import React from "react";
import { render, unmountComponentAtNode } from "react-dom";
import "core-js/es6/promise";
import elementClosest from "element-closest";
import {
	getDataAttribute,
	shadowDOM,
	externalStylesheet,
	injectFont,
} from "./utils";

import __PACKAGE__ from "../package.json";

class FriendsAndFamily {
	constructor() {
		// Expose client-side library to interact with widget
		window.WEB_CDN_FNF = this;

		this.version = __PACKAGE__.version;
		this.build = process.env.BUILD_ID;
		this.isInitializing = false;
		this.isInitialized = false;
		this._reactRoot = null;
		this.init();
	}

	init() {
		/* Initialize shadow dom */
		const [appDOM, reactRoot, reactDOM] = shadowDOM(__PACKAGE__.name);

		// Inject sunwing fonts
		injectFont(appDOM, "https://www.sunwing.ca/vendors/fonts.css");

		// Inject sunwing fonts
		injectFont(
			appDOM,
			"https://fonts.googleapis.com/css?family=PT+Sans:400,700"
		);

		// Inject sunwing icons
		injectFont(
			appDOM,
			`${process.env.SWG_ICON_BASE_URL}/lib/sunwing/css/icons.css`
		);

		/* Save reference to reactRoot in order to be used to unmount */
		this._reactRoot = reactRoot;

		/* Initialize Element.closest() polyfill for IE11) */
		elementClosest(window);

		/* Retrieve app settings from data attributes / configuration */
		/**
		 * Get type from data attribute or use default
		 * @category App Initialization
		 * @type {string}
		 * @default fnf
		 */
		const type = getDataAttribute(appDOM, "type", "fnf");

		/**
		 * Get language from data attribute or use default
		 * @category App Initialization
		 * @type {string}
		 * @default en
		 */
		const lang = getDataAttribute(appDOM, "lang", "en");

		/**
		 * Get api from data attribute or use default
		 * @category App Initialization
		 * @type {string}
		 * @default process.env.LOYALTY_API
		 */
		const apiUrl = getDataAttribute(appDOM, "apiUrl", process.env.LOYALTY_API);

		/**
		 * Get token from data attribute
		 * @category App Initialization
		 * @type {string}
		 * @default ""
		 */
		const apiToken = getDataAttribute(appDOM, "apiToken", "");

		/**
		 * Get Logging API url from data attribute or use default
		 * @category App Initialization
		 * @type {string | string}
		 * @default https://weblogging.sunwingtravelgroup.com/api/v1/
		 */
		// const loggingApi = getDataAttribute(appDOM, "loggingApi", LOGGING_API);

		/**
		 * Get configuration for enabling Logging API from data attribute or use default
		 * @category App Initialization
		 * @type {string | string}
		 * @default "true"
		 */
		// const enableLogging = getDataAttribute(
		// 	appDOM,
		// 	"enableLogging",
		// 	ENABLE_LOGGING
		// );

		/**
		 * Get stylesheet override url from data attribute or use default
		 * @category App Initialization
		 * @type {string | undefined}
		 * @default undefined
		 */
		const stylesheet = getDataAttribute(appDOM, "stylesheet", undefined);

		/* Load external stylesheet into shadow dom / dom when supplied */
		externalStylesheet(reactDOM, stylesheet);

		import("./App.js").then(({ default: App }) => {
			this.isInitializing = false;

			render(
				<App
					lang={lang}
					type={type}
					reactDOM={reactDOM}
					appRoot={reactRoot}
					apiUrl={apiUrl}
					apiToken={apiToken}
				/>,
				reactRoot
			);
		});

		this.isInitialized = true;

		return this;
	}

	onLoad(callback) {
		if (callback && typeof callback === "function") {
			callback(this);
		}

		return this;
	}

	destroy() {
		if (this.isInitialized) {
			try {
				if (unmountComponentAtNode(this._reactRoot)) {
					this.isInitialized = false;
				} else {
					console.error("WEB.CDN.FNF | Unable to unmount application");
				}
			} catch (error) {
				console.error("WEB.CDN.FNF | Unable to unmount application", error);
			}
		}
	}
}

window.WEB_CDN_FNF = new FriendsAndFamily();
